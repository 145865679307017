/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminChatAudit,
  AdminV1ChatAuditsListParams,
  PaginatedAdminChatAuditList
} from '.././model'
import adminV1ChatAuditsListMutator from '../../shared/services/axios-client';
import adminV1ChatAuditsRetrieveMutator from '../../shared/services/axios-client';



  export const adminV1ChatAuditsList = (
    params?: AdminV1ChatAuditsListParams,
 ) => {
      return adminV1ChatAuditsListMutator<PaginatedAdminChatAuditList>(
      {url: `/api/admin/v1/chat_audits/`, method: 'GET',
        params
    },
      );
    }
  export const adminV1ChatAuditsRetrieve = (
    id: number,
 ) => {
      return adminV1ChatAuditsRetrieveMutator<AdminChatAudit>(
      {url: `/api/admin/v1/chat_audits/${id}/`, method: 'GET'
    },
      );
    }
  export type AdminV1ChatAuditsListResult = NonNullable<Awaited<ReturnType<typeof adminV1ChatAuditsList>>>
export type AdminV1ChatAuditsRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1ChatAuditsRetrieve>>>
