/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminProfile,
  AdminV1ProfilesListParams,
  PaginatedAdminProfileList,
  PatchedAdminProfile
} from '.././model'
import adminV1ProfilesListMutator from '../../shared/services/axios-client';
import adminV1ProfilesCreateMutator from '../../shared/services/axios-client';
import adminV1ProfilesRetrieveMutator from '../../shared/services/axios-client';
import adminV1ProfilesUpdateMutator from '../../shared/services/axios-client';
import adminV1ProfilesPartialUpdateMutator from '../../shared/services/axios-client';
import adminV1ProfilesDestroyMutator from '../../shared/services/axios-client';
import adminV1ProfilesTeamsCreateMutator from '../../shared/services/axios-client';
import adminV1ProfilesTeamsDestroyMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;




  export const adminV1ProfilesList = (
    params?: AdminV1ProfilesListParams,
 ) => {
      return adminV1ProfilesListMutator<PaginatedAdminProfileList>(
      {url: `/api/admin/v1/profiles/`, method: 'GET',
        params
    },
      );
    }
  export const adminV1ProfilesCreate = (
    adminProfile: NonReadonly<AdminProfile>,
 ) => {
      return adminV1ProfilesCreateMutator<AdminProfile>(
      {url: `/api/admin/v1/profiles/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminProfile
    },
      );
    }
  export const adminV1ProfilesRetrieve = (
    id: number,
 ) => {
      return adminV1ProfilesRetrieveMutator<AdminProfile>(
      {url: `/api/admin/v1/profiles/${id}/`, method: 'GET'
    },
      );
    }
  export const adminV1ProfilesUpdate = (
    id: number,
    adminProfile: NonReadonly<AdminProfile>,
 ) => {
      return adminV1ProfilesUpdateMutator<AdminProfile>(
      {url: `/api/admin/v1/profiles/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: adminProfile
    },
      );
    }
  export const adminV1ProfilesPartialUpdate = (
    id: number,
    patchedAdminProfile: NonReadonly<PatchedAdminProfile>,
 ) => {
      return adminV1ProfilesPartialUpdateMutator<AdminProfile>(
      {url: `/api/admin/v1/profiles/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedAdminProfile
    },
      );
    }
  export const adminV1ProfilesDestroy = (
    id: number,
 ) => {
      return adminV1ProfilesDestroyMutator<void>(
      {url: `/api/admin/v1/profiles/${id}/`, method: 'DELETE'
    },
      );
    }
  export const adminV1ProfilesTeamsCreate = (
    id: number,
    teamId: number,
 ) => {
      return adminV1ProfilesTeamsCreateMutator<AdminProfile>(
      {url: `/api/admin/v1/profiles/${id}/teams/${teamId}/`, method: 'POST'
    },
      );
    }
  export const adminV1ProfilesTeamsDestroy = (
    id: number,
    teamId: number,
 ) => {
      return adminV1ProfilesTeamsDestroyMutator<void>(
      {url: `/api/admin/v1/profiles/${id}/teams/${teamId}/`, method: 'DELETE'
    },
      );
    }
  export type AdminV1ProfilesListResult = NonNullable<Awaited<ReturnType<typeof adminV1ProfilesList>>>
export type AdminV1ProfilesCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1ProfilesCreate>>>
export type AdminV1ProfilesRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1ProfilesRetrieve>>>
export type AdminV1ProfilesUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1ProfilesUpdate>>>
export type AdminV1ProfilesPartialUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1ProfilesPartialUpdate>>>
export type AdminV1ProfilesDestroyResult = NonNullable<Awaited<ReturnType<typeof adminV1ProfilesDestroy>>>
export type AdminV1ProfilesTeamsCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1ProfilesTeamsCreate>>>
export type AdminV1ProfilesTeamsDestroyResult = NonNullable<Awaited<ReturnType<typeof adminV1ProfilesTeamsDestroy>>>
