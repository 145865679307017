/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminV1TagsListParams,
  PaginatedAdminTagList
} from '.././model'
import adminV1TagsListMutator from '../../shared/services/axios-client';



  export const adminV1TagsList = (
    params?: AdminV1TagsListParams,
 ) => {
      return adminV1TagsListMutator<PaginatedAdminTagList>(
      {url: `/api/admin/v1/tags/`, method: 'GET',
        params
    },
      );
    }
  export type AdminV1TagsListResult = NonNullable<Awaited<ReturnType<typeof adminV1TagsList>>>
