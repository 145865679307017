import type { Ref } from "vue";
import type {
  InvoiceStatus,
  LineItem,
  AssociatedContactDropdownOption,
  InvoiceFormFields,
  FormValidation,
  Recipient,
  InvoiceRecipient,
} from "@/types";

import { ref } from "vue";
import { required, helpers, minValue } from "@vuelidate/validators";
import type { Invoice } from "@/api/model";
import useVuelidate from "@vuelidate/core";
import { BLANK_INVOICE_LINE_ITEM } from "../constants";

interface UseFormInvoiceForm {
  invoiceTitle: Ref<string | null>;
  invoiceStatus?: Ref<InvoiceStatus | null>;
  invoiceDueDate: Ref<string | null>;
  invoiceCreatedAt: Ref<Date | null>;
  lineItems: Ref<LineItem[]>;
  invoiceTotal?: Ref<number | null>;
  playerId: Ref<number | null>;
  formValidations: Ref<FormValidation>;
  contact: Ref<AssociatedContactDropdownOption | null>;
  invoiceFormFields: InvoiceFormFields;
  buildFormFields: (invoice: Invoice) => void;
  addBlankLineItem: () => void;
}
export const useInvoiceForm = (): UseFormInvoiceForm => {
  const invoiceTitle = ref<string | null>(null);
  const invoiceStatus = ref<InvoiceStatus | null>(null);
  const invoiceDueDate = ref<string | null>(null);
  const invoiceCreatedAt = ref<Date | null>(null);
  const lineItems = ref<LineItem[]>([]);
  const invoiceTotal = ref<number | null>(null);

  const invoiceRecipients = ref<InvoiceRecipient[]>([]);

  const playerId = ref<number | null>(null);
  const contact = ref<AssociatedContactDropdownOption | null>(null);

  const invoiceFormFields: InvoiceFormFields = {
    invoiceTitle,
    invoiceStatus,
    invoiceDueDate,
    lineItems,
    invoiceTotal,
    playerId,
    contact,
    invoiceCreatedAt,
    invoiceRecipients,
  };

  const rules = {
    invoiceDueDate: { required },
    lineItems: {
      $each: helpers.forEach({
        itemName: { required },
        itemPrice: { required },
        itemQuantity: { required, minValue: minValue(1) },
        itemCategory: { required },
      }),
    },
  };

  const formValidations = useVuelidate(rules, invoiceFormFields, {
    $scope: false,
  });
  formValidations.value.$reset();

  const buildFormFields = (invoice: Invoice) => {
    invoice.items.map((item) => {
      lineItems.value.push({
        itemName: item.item_product_name,
        itemPrice: item.item_cost,
        itemQuantity: item.item_qty,
        itemCategory: item.item_categories[0],
      });
    });

    invoiceTitle.value = invoice.invoice_title;
    invoiceDueDate.value = invoice.invoice_due_date;
    playerId.value = invoice.profile?.id;
    contact.value = {
      id: invoice.invoice_recipient?.id,
      email: invoice.invoice_recipient?.email,
      name: invoice.invoice_recipient?.name,
    };

    invoiceStatus.value = invoice.invoice_status as InvoiceStatus;
    invoiceTotal.value = invoice.invoice_amount;
    invoiceCreatedAt.value = new Date(invoice.invoice_created_at);
  };

  const addBlankLineItem = () => {
    lineItems.value.push({ ...BLANK_INVOICE_LINE_ITEM });
  };

  return {
    invoiceTitle,
    invoiceStatus,
    invoiceDueDate,
    lineItems,
    invoiceTotal,
    playerId,
    contact,
    invoiceFormFields,
    formValidations,
    invoiceCreatedAt,
    buildFormFields,
    addBlankLineItem,
  };
};
