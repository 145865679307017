/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminPaymentSetting
} from '.././model'
import adminV1OrganizationsPaymentSettingsRetrieveMutator from '../../shared/services/axios-client';
import adminV1OrganizationsPaymentSettingsCreateMutator from '../../shared/services/axios-client';
import adminV1OrganizationsPaymentSettingsUpdateMutator from '../../shared/services/axios-client';
import adminV1OrganizationsPaymentSettingsDestroyMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;




  export const adminV1OrganizationsPaymentSettingsRetrieve = (
    orgPk: number,
 ) => {
      return adminV1OrganizationsPaymentSettingsRetrieveMutator<AdminPaymentSetting>(
      {url: `/api/admin/v1/organizations/${orgPk}/payment_settings/`, method: 'GET'
    },
      );
    }
  export const adminV1OrganizationsPaymentSettingsCreate = (
    orgPk: number,
    adminPaymentSetting: NonReadonly<AdminPaymentSetting>,
 ) => {
      return adminV1OrganizationsPaymentSettingsCreateMutator<AdminPaymentSetting>(
      {url: `/api/admin/v1/organizations/${orgPk}/payment_settings/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminPaymentSetting
    },
      );
    }
  export const adminV1OrganizationsPaymentSettingsUpdate = (
    orgPk: number,
    adminPaymentSetting: NonReadonly<AdminPaymentSetting>,
 ) => {
      return adminV1OrganizationsPaymentSettingsUpdateMutator<AdminPaymentSetting>(
      {url: `/api/admin/v1/organizations/${orgPk}/payment_settings/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: adminPaymentSetting
    },
      );
    }
  export const adminV1OrganizationsPaymentSettingsDestroy = (
    orgPk: number,
 ) => {
      return adminV1OrganizationsPaymentSettingsDestroyMutator<void>(
      {url: `/api/admin/v1/organizations/${orgPk}/payment_settings/`, method: 'DELETE'
    },
      );
    }
  export type AdminV1OrganizationsPaymentSettingsRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1OrganizationsPaymentSettingsRetrieve>>>
export type AdminV1OrganizationsPaymentSettingsCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1OrganizationsPaymentSettingsCreate>>>
export type AdminV1OrganizationsPaymentSettingsUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1OrganizationsPaymentSettingsUpdate>>>
export type AdminV1OrganizationsPaymentSettingsDestroyResult = NonNullable<Awaited<ReturnType<typeof adminV1OrganizationsPaymentSettingsDestroy>>>
