/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminDrillAsset,
  AdminV1DrillsAssetsListParams,
  PaginatedAdminDrillAssetList,
  PatchedAdminDrillAsset
} from '.././model'
import adminV1DrillsAssetsListMutator from '../../shared/services/axios-client';
import adminV1DrillsAssetsCreateMutator from '../../shared/services/axios-client';
import adminV1DrillsAssetsRetrieveMutator from '../../shared/services/axios-client';
import adminV1DrillsAssetsUpdateMutator from '../../shared/services/axios-client';
import adminV1DrillsAssetsPartialUpdateMutator from '../../shared/services/axios-client';
import adminV1DrillsAssetsDestroyMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;




  export const adminV1DrillsAssetsList = (
    params?: AdminV1DrillsAssetsListParams,
 ) => {
      return adminV1DrillsAssetsListMutator<PaginatedAdminDrillAssetList>(
      {url: `/api/admin/v1/drills_assets/`, method: 'GET',
        params
    },
      );
    }
  export const adminV1DrillsAssetsCreate = (
    adminDrillAsset: NonReadonly<AdminDrillAsset>,
 ) => {
      return adminV1DrillsAssetsCreateMutator<AdminDrillAsset>(
      {url: `/api/admin/v1/drills_assets/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminDrillAsset
    },
      );
    }
  export const adminV1DrillsAssetsRetrieve = (
    id: number,
 ) => {
      return adminV1DrillsAssetsRetrieveMutator<AdminDrillAsset>(
      {url: `/api/admin/v1/drills_assets/${id}/`, method: 'GET'
    },
      );
    }
  export const adminV1DrillsAssetsUpdate = (
    id: number,
    adminDrillAsset: NonReadonly<AdminDrillAsset>,
 ) => {
      return adminV1DrillsAssetsUpdateMutator<AdminDrillAsset>(
      {url: `/api/admin/v1/drills_assets/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: adminDrillAsset
    },
      );
    }
  export const adminV1DrillsAssetsPartialUpdate = (
    id: number,
    patchedAdminDrillAsset: NonReadonly<PatchedAdminDrillAsset>,
 ) => {
      return adminV1DrillsAssetsPartialUpdateMutator<AdminDrillAsset>(
      {url: `/api/admin/v1/drills_assets/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedAdminDrillAsset
    },
      );
    }
  export const adminV1DrillsAssetsDestroy = (
    id: number,
 ) => {
      return adminV1DrillsAssetsDestroyMutator<void>(
      {url: `/api/admin/v1/drills_assets/${id}/`, method: 'DELETE'
    },
      );
    }
  export type AdminV1DrillsAssetsListResult = NonNullable<Awaited<ReturnType<typeof adminV1DrillsAssetsList>>>
export type AdminV1DrillsAssetsCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1DrillsAssetsCreate>>>
export type AdminV1DrillsAssetsRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1DrillsAssetsRetrieve>>>
export type AdminV1DrillsAssetsUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1DrillsAssetsUpdate>>>
export type AdminV1DrillsAssetsPartialUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1DrillsAssetsPartialUpdate>>>
export type AdminV1DrillsAssetsDestroyResult = NonNullable<Awaited<ReturnType<typeof adminV1DrillsAssetsDestroy>>>
