/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminV1CloudfrontCookiesRetrieveParams,
  CloudFrontCookiesResponse
} from '.././model'
import adminV1CloudfrontCookiesRetrieveMutator from '../../shared/services/axios-client';



  export const adminV1CloudfrontCookiesRetrieve = (
    params?: AdminV1CloudfrontCookiesRetrieveParams,
 ) => {
      return adminV1CloudfrontCookiesRetrieveMutator<CloudFrontCookiesResponse>(
      {url: `/api/admin/v1/cloudfront-cookies/`, method: 'GET',
        params
    },
      );
    }
  export type AdminV1CloudfrontCookiesRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1CloudfrontCookiesRetrieve>>>
