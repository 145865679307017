/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminDrill,
  AdminTrainingCollectionDrillList,
  AdminV1DrillsListParams,
  DrillsOrderResponse,
  OrderDrills,
  PatchedAdminDrill,
  SwaggerAdminDrill
} from '.././model'
import adminV1DrillsListMutator from '../../shared/services/axios-client';
import adminV1DrillsCreateMutator from '../../shared/services/axios-client';
import adminV1DrillsRetrieveMutator from '../../shared/services/axios-client';
import adminV1DrillsUpdateMutator from '../../shared/services/axios-client';
import adminV1DrillsPartialUpdateMutator from '../../shared/services/axios-client';
import adminV1DrillsDestroyMutator from '../../shared/services/axios-client';
import adminV1DrillsUpdateOrderCreateMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;




  export const adminV1DrillsList = (
    params?: AdminV1DrillsListParams,
 ) => {
      return adminV1DrillsListMutator<AdminTrainingCollectionDrillList[]>(
      {url: `/api/admin/v1/drills/`, method: 'GET',
        params
    },
      );
    }
  export const adminV1DrillsCreate = (
    swaggerAdminDrill: NonReadonly<SwaggerAdminDrill>,
 ) => {
      return adminV1DrillsCreateMutator<AdminDrill>(
      {url: `/api/admin/v1/drills/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: swaggerAdminDrill
    },
      );
    }
  export const adminV1DrillsRetrieve = (
    id: number,
 ) => {
      return adminV1DrillsRetrieveMutator<AdminDrill>(
      {url: `/api/admin/v1/drills/${id}/`, method: 'GET'
    },
      );
    }
  export const adminV1DrillsUpdate = (
    id: number,
    adminDrill: NonReadonly<AdminDrill>,
 ) => {
      return adminV1DrillsUpdateMutator<AdminDrill>(
      {url: `/api/admin/v1/drills/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: adminDrill
    },
      );
    }
  export const adminV1DrillsPartialUpdate = (
    id: number,
    patchedAdminDrill: NonReadonly<PatchedAdminDrill>,
 ) => {
      return adminV1DrillsPartialUpdateMutator<AdminDrill>(
      {url: `/api/admin/v1/drills/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedAdminDrill
    },
      );
    }
  export const adminV1DrillsDestroy = (
    id: number,
 ) => {
      return adminV1DrillsDestroyMutator<void>(
      {url: `/api/admin/v1/drills/${id}/`, method: 'DELETE'
    },
      );
    }
  export const adminV1DrillsUpdateOrderCreate = (
    orderDrills: OrderDrills[],
 ) => {
      return adminV1DrillsUpdateOrderCreateMutator<DrillsOrderResponse>(
      {url: `/api/admin/v1/drills/update_order/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: orderDrills
    },
      );
    }
  export type AdminV1DrillsListResult = NonNullable<Awaited<ReturnType<typeof adminV1DrillsList>>>
export type AdminV1DrillsCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1DrillsCreate>>>
export type AdminV1DrillsRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1DrillsRetrieve>>>
export type AdminV1DrillsUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1DrillsUpdate>>>
export type AdminV1DrillsPartialUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1DrillsPartialUpdate>>>
export type AdminV1DrillsDestroyResult = NonNullable<Awaited<ReturnType<typeof adminV1DrillsDestroy>>>
export type AdminV1DrillsUpdateOrderCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1DrillsUpdateOrderCreate>>>
