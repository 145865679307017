/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminBlock,
  AdminBlockCreate,
  PatchedAdminBlock
} from '.././model'
import adminV1BlocksCreateMutator from '../../shared/services/axios-client';
import adminV1BlocksUpdateMutator from '../../shared/services/axios-client';
import adminV1BlocksPartialUpdateMutator from '../../shared/services/axios-client';
import adminV1BlocksDestroyMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;




  export const adminV1BlocksCreate = (
    adminBlockCreate: NonReadonly<AdminBlockCreate>,
 ) => {
      return adminV1BlocksCreateMutator<AdminBlockCreate>(
      {url: `/api/admin/v1/blocks/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminBlockCreate
    },
      );
    }
  export const adminV1BlocksUpdate = (
    id: number,
    adminBlock: NonReadonly<AdminBlock>,
 ) => {
      return adminV1BlocksUpdateMutator<AdminBlock>(
      {url: `/api/admin/v1/blocks/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: adminBlock
    },
      );
    }
  export const adminV1BlocksPartialUpdate = (
    id: number,
    patchedAdminBlock: NonReadonly<PatchedAdminBlock>,
 ) => {
      return adminV1BlocksPartialUpdateMutator<AdminBlock>(
      {url: `/api/admin/v1/blocks/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedAdminBlock
    },
      );
    }
  export const adminV1BlocksDestroy = (
    id: number,
 ) => {
      return adminV1BlocksDestroyMutator<void>(
      {url: `/api/admin/v1/blocks/${id}/`, method: 'DELETE'
    },
      );
    }
  export type AdminV1BlocksCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1BlocksCreate>>>
export type AdminV1BlocksUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1BlocksUpdate>>>
export type AdminV1BlocksPartialUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1BlocksPartialUpdate>>>
export type AdminV1BlocksDestroyResult = NonNullable<Awaited<ReturnType<typeof adminV1BlocksDestroy>>>
