/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminTrainingCollectionAsset,
  AdminV1TrainingCollectionsAssetsListParams,
  PaginatedAdminTrainingCollectionAssetList
} from '.././model'
import adminV1TrainingCollectionsAssetsListMutator from '../../shared/services/axios-client';
import adminV1TrainingCollectionsAssetsCreateMutator from '../../shared/services/axios-client';
import adminV1TrainingCollectionsAssetsRetrieveMutator from '../../shared/services/axios-client';
import adminV1TrainingCollectionsAssetsDestroyMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;




  export const adminV1TrainingCollectionsAssetsList = (
    params?: AdminV1TrainingCollectionsAssetsListParams,
 ) => {
      return adminV1TrainingCollectionsAssetsListMutator<PaginatedAdminTrainingCollectionAssetList>(
      {url: `/api/admin/v1/training_collections_assets/`, method: 'GET',
        params
    },
      );
    }
  /**
 * Create OR Update a training collection asset.

If the training collection asset already exists by `training_collection_id` and `type`, it will be updated with the new asset.
 * @summary Create OR Update a training collection asset.
 */
export const adminV1TrainingCollectionsAssetsCreate = (
    adminTrainingCollectionAsset: NonReadonly<AdminTrainingCollectionAsset>,
 ) => {
      return adminV1TrainingCollectionsAssetsCreateMutator<AdminTrainingCollectionAsset>(
      {url: `/api/admin/v1/training_collections_assets/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminTrainingCollectionAsset
    },
      );
    }
  export const adminV1TrainingCollectionsAssetsRetrieve = (
    id: number,
 ) => {
      return adminV1TrainingCollectionsAssetsRetrieveMutator<AdminTrainingCollectionAsset>(
      {url: `/api/admin/v1/training_collections_assets/${id}/`, method: 'GET'
    },
      );
    }
  export const adminV1TrainingCollectionsAssetsDestroy = (
    id: number,
 ) => {
      return adminV1TrainingCollectionsAssetsDestroyMutator<void>(
      {url: `/api/admin/v1/training_collections_assets/${id}/`, method: 'DELETE'
    },
      );
    }
  export type AdminV1TrainingCollectionsAssetsListResult = NonNullable<Awaited<ReturnType<typeof adminV1TrainingCollectionsAssetsList>>>
export type AdminV1TrainingCollectionsAssetsCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1TrainingCollectionsAssetsCreate>>>
export type AdminV1TrainingCollectionsAssetsRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1TrainingCollectionsAssetsRetrieve>>>
export type AdminV1TrainingCollectionsAssetsDestroyResult = NonNullable<Awaited<ReturnType<typeof adminV1TrainingCollectionsAssetsDestroy>>>
