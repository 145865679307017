/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminV1ExercisesListParams,
  AdminWorkoutsExercise,
  PaginatedAdminExerciseListList,
  PatchedAdminWorkoutsExercise
} from '.././model'
import adminV1ExercisesListMutator from '../../shared/services/axios-client';
import adminV1ExercisesCreateMutator from '../../shared/services/axios-client';
import adminV1ExercisesRetrieveMutator from '../../shared/services/axios-client';
import adminV1ExercisesUpdateMutator from '../../shared/services/axios-client';
import adminV1ExercisesPartialUpdateMutator from '../../shared/services/axios-client';
import adminV1ExercisesDestroyMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;




  export const adminV1ExercisesList = (
    params?: AdminV1ExercisesListParams,
 ) => {
      return adminV1ExercisesListMutator<PaginatedAdminExerciseListList>(
      {url: `/api/admin/v1/exercises/`, method: 'GET',
        params
    },
      );
    }
  export const adminV1ExercisesCreate = (
    adminWorkoutsExercise: NonReadonly<AdminWorkoutsExercise>,
 ) => {
      return adminV1ExercisesCreateMutator<AdminWorkoutsExercise>(
      {url: `/api/admin/v1/exercises/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminWorkoutsExercise
    },
      );
    }
  export const adminV1ExercisesRetrieve = (
    id: number,
 ) => {
      return adminV1ExercisesRetrieveMutator<AdminWorkoutsExercise>(
      {url: `/api/admin/v1/exercises/${id}/`, method: 'GET'
    },
      );
    }
  export const adminV1ExercisesUpdate = (
    id: number,
    adminWorkoutsExercise: NonReadonly<AdminWorkoutsExercise>,
 ) => {
      return adminV1ExercisesUpdateMutator<AdminWorkoutsExercise>(
      {url: `/api/admin/v1/exercises/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: adminWorkoutsExercise
    },
      );
    }
  export const adminV1ExercisesPartialUpdate = (
    id: number,
    patchedAdminWorkoutsExercise: NonReadonly<PatchedAdminWorkoutsExercise>,
 ) => {
      return adminV1ExercisesPartialUpdateMutator<AdminWorkoutsExercise>(
      {url: `/api/admin/v1/exercises/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedAdminWorkoutsExercise
    },
      );
    }
  export const adminV1ExercisesDestroy = (
    id: number,
 ) => {
      return adminV1ExercisesDestroyMutator<void>(
      {url: `/api/admin/v1/exercises/${id}/`, method: 'DELETE'
    },
      );
    }
  export type AdminV1ExercisesListResult = NonNullable<Awaited<ReturnType<typeof adminV1ExercisesList>>>
export type AdminV1ExercisesCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1ExercisesCreate>>>
export type AdminV1ExercisesRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1ExercisesRetrieve>>>
export type AdminV1ExercisesUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1ExercisesUpdate>>>
export type AdminV1ExercisesPartialUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1ExercisesPartialUpdate>>>
export type AdminV1ExercisesDestroyResult = NonNullable<Awaited<ReturnType<typeof adminV1ExercisesDestroy>>>
