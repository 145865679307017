/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminV1WorkoutsListParams,
  AdminWorkout,
  CopyWorkout,
  CopyWorkoutResponse,
  PatchedAdminWorkout,
  SwaggerAdminWorkoutList
} from '.././model'
import adminV1WorkoutsListMutator from '../../shared/services/axios-client';
import adminV1WorkoutsCreateMutator from '../../shared/services/axios-client';
import adminV1WorkoutsRetrieveMutator from '../../shared/services/axios-client';
import adminV1WorkoutsUpdateMutator from '../../shared/services/axios-client';
import adminV1WorkoutsPartialUpdateMutator from '../../shared/services/axios-client';
import adminV1WorkoutsDestroyMutator from '../../shared/services/axios-client';
import adminV1WorkoutsCopyWorkoutCreateMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;




  export const adminV1WorkoutsList = (
    params: AdminV1WorkoutsListParams,
 ) => {
      return adminV1WorkoutsListMutator<SwaggerAdminWorkoutList>(
      {url: `/api/admin/v1/workouts/`, method: 'GET',
        params
    },
      );
    }
  export const adminV1WorkoutsCreate = (
    adminWorkout: NonReadonly<AdminWorkout>,
 ) => {
      return adminV1WorkoutsCreateMutator<AdminWorkout>(
      {url: `/api/admin/v1/workouts/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminWorkout
    },
      );
    }
  export const adminV1WorkoutsRetrieve = (
    id: number,
 ) => {
      return adminV1WorkoutsRetrieveMutator<AdminWorkout>(
      {url: `/api/admin/v1/workouts/${id}/`, method: 'GET'
    },
      );
    }
  export const adminV1WorkoutsUpdate = (
    id: number,
    adminWorkout: NonReadonly<AdminWorkout>,
 ) => {
      return adminV1WorkoutsUpdateMutator<AdminWorkout>(
      {url: `/api/admin/v1/workouts/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: adminWorkout
    },
      );
    }
  export const adminV1WorkoutsPartialUpdate = (
    id: number,
    patchedAdminWorkout: NonReadonly<PatchedAdminWorkout>,
 ) => {
      return adminV1WorkoutsPartialUpdateMutator<AdminWorkout>(
      {url: `/api/admin/v1/workouts/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedAdminWorkout
    },
      );
    }
  export const adminV1WorkoutsDestroy = (
    id: number,
 ) => {
      return adminV1WorkoutsDestroyMutator<void>(
      {url: `/api/admin/v1/workouts/${id}/`, method: 'DELETE'
    },
      );
    }
  export const adminV1WorkoutsCopyWorkoutCreate = (
    copyWorkout: CopyWorkout,
 ) => {
      return adminV1WorkoutsCopyWorkoutCreateMutator<CopyWorkoutResponse>(
      {url: `/api/admin/v1/workouts/copy_workout/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: copyWorkout
    },
      );
    }
  export type AdminV1WorkoutsListResult = NonNullable<Awaited<ReturnType<typeof adminV1WorkoutsList>>>
export type AdminV1WorkoutsCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1WorkoutsCreate>>>
export type AdminV1WorkoutsRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1WorkoutsRetrieve>>>
export type AdminV1WorkoutsUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1WorkoutsUpdate>>>
export type AdminV1WorkoutsPartialUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1WorkoutsPartialUpdate>>>
export type AdminV1WorkoutsDestroyResult = NonNullable<Awaited<ReturnType<typeof adminV1WorkoutsDestroy>>>
export type AdminV1WorkoutsCopyWorkoutCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1WorkoutsCopyWorkoutCreate>>>
