/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminTeam,
  AdminV1TeamsListParams,
  PaginatedAdminTeamList,
  PatchedAdminTeam
} from '.././model'
import adminV1TeamsListMutator from '../../shared/services/axios-client';
import adminV1TeamsCreateMutator from '../../shared/services/axios-client';
import adminV1TeamsRetrieveMutator from '../../shared/services/axios-client';
import adminV1TeamsUpdateMutator from '../../shared/services/axios-client';
import adminV1TeamsPartialUpdateMutator from '../../shared/services/axios-client';
import adminV1TeamsDestroyMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;




  export const adminV1TeamsList = (
    params?: AdminV1TeamsListParams,
 ) => {
      return adminV1TeamsListMutator<PaginatedAdminTeamList>(
      {url: `/api/admin/v1/teams/`, method: 'GET',
        params
    },
      );
    }
  export const adminV1TeamsCreate = (
    adminTeam: NonReadonly<AdminTeam>,
 ) => {
      return adminV1TeamsCreateMutator<AdminTeam>(
      {url: `/api/admin/v1/teams/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminTeam
    },
      );
    }
  export const adminV1TeamsRetrieve = (
    id: number,
 ) => {
      return adminV1TeamsRetrieveMutator<AdminTeam>(
      {url: `/api/admin/v1/teams/${id}/`, method: 'GET'
    },
      );
    }
  export const adminV1TeamsUpdate = (
    id: number,
    adminTeam: NonReadonly<AdminTeam>,
 ) => {
      return adminV1TeamsUpdateMutator<AdminTeam>(
      {url: `/api/admin/v1/teams/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: adminTeam
    },
      );
    }
  export const adminV1TeamsPartialUpdate = (
    id: number,
    patchedAdminTeam: NonReadonly<PatchedAdminTeam>,
 ) => {
      return adminV1TeamsPartialUpdateMutator<AdminTeam>(
      {url: `/api/admin/v1/teams/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedAdminTeam
    },
      );
    }
  export const adminV1TeamsDestroy = (
    id: number,
 ) => {
      return adminV1TeamsDestroyMutator<void>(
      {url: `/api/admin/v1/teams/${id}/`, method: 'DELETE'
    },
      );
    }
  export type AdminV1TeamsListResult = NonNullable<Awaited<ReturnType<typeof adminV1TeamsList>>>
export type AdminV1TeamsCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1TeamsCreate>>>
export type AdminV1TeamsRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1TeamsRetrieve>>>
export type AdminV1TeamsUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1TeamsUpdate>>>
export type AdminV1TeamsPartialUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1TeamsPartialUpdate>>>
export type AdminV1TeamsDestroyResult = NonNullable<Awaited<ReturnType<typeof adminV1TeamsDestroy>>>
