/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminV1PaymentsInvoicesListParams,
  AdminV1PaymentsInvoicesUpdate200,
  CreateInvoices,
  Invoice,
  InvoiceCreated,
  PaginatedInvoiceListList,
  UpdateInvoice
} from '.././model'
import adminV1PaymentsInvoicesListMutator from '../../shared/services/axios-client';
import adminV1PaymentsInvoicesCreateMutator from '../../shared/services/axios-client';
import adminV1PaymentsInvoicesRetrieveMutator from '../../shared/services/axios-client';
import adminV1PaymentsInvoicesUpdateMutator from '../../shared/services/axios-client';



  export const adminV1PaymentsInvoicesList = (
    params?: AdminV1PaymentsInvoicesListParams,
 ) => {
      return adminV1PaymentsInvoicesListMutator<PaginatedInvoiceListList>(
      {url: `/api/admin/v1/payments/invoices/`, method: 'GET',
        params
    },
      );
    }
  export const adminV1PaymentsInvoicesCreate = (
    createInvoices: CreateInvoices,
 ) => {
      return adminV1PaymentsInvoicesCreateMutator<InvoiceCreated>(
      {url: `/api/admin/v1/payments/invoices/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createInvoices
    },
      );
    }
  export const adminV1PaymentsInvoicesRetrieve = (
    id: string,
 ) => {
      return adminV1PaymentsInvoicesRetrieveMutator<Invoice>(
      {url: `/api/admin/v1/payments/invoices/${id}/`, method: 'GET'
    },
      );
    }
  export const adminV1PaymentsInvoicesUpdate = (
    id: string,
    updateInvoice: UpdateInvoice,
 ) => {
      return adminV1PaymentsInvoicesUpdateMutator<AdminV1PaymentsInvoicesUpdate200>(
      {url: `/api/admin/v1/payments/invoices/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateInvoice
    },
      );
    }
  export type AdminV1PaymentsInvoicesListResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsInvoicesList>>>
export type AdminV1PaymentsInvoicesCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsInvoicesCreate>>>
export type AdminV1PaymentsInvoicesRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsInvoicesRetrieve>>>
export type AdminV1PaymentsInvoicesUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsInvoicesUpdate>>>
