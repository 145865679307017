/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminTrainingCollectionComparisonVideo,
  AdminV1TrainingCollectionsComparisonVideosListParams,
  OrderTrainingCollectionComparisonVideos,
  OrderTrainingCollectionComparisonVideosResponse,
  PaginatedAdminTrainingCollectionComparisonVideoList,
  PatchedAdminTrainingCollectionComparisonVideo
} from '.././model'
import adminV1TrainingCollectionsComparisonVideosListMutator from '../../shared/services/axios-client';
import adminV1TrainingCollectionsComparisonVideosCreateMutator from '../../shared/services/axios-client';
import adminV1TrainingCollectionsComparisonVideosRetrieveMutator from '../../shared/services/axios-client';
import adminV1TrainingCollectionsComparisonVideosUpdateMutator from '../../shared/services/axios-client';
import adminV1TrainingCollectionsComparisonVideosPartialUpdateMutator from '../../shared/services/axios-client';
import adminV1TrainingCollectionsComparisonVideosDestroyMutator from '../../shared/services/axios-client';
import adminV1TrainingCollectionsComparisonVideosUpdateVideosOrderCreateMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;




  export const adminV1TrainingCollectionsComparisonVideosList = (
    params?: AdminV1TrainingCollectionsComparisonVideosListParams,
 ) => {
      return adminV1TrainingCollectionsComparisonVideosListMutator<PaginatedAdminTrainingCollectionComparisonVideoList>(
      {url: `/api/admin/v1/training_collections_comparison_videos/`, method: 'GET',
        params
    },
      );
    }
  export const adminV1TrainingCollectionsComparisonVideosCreate = (
    adminTrainingCollectionComparisonVideo: NonReadonly<AdminTrainingCollectionComparisonVideo>,
 ) => {
      return adminV1TrainingCollectionsComparisonVideosCreateMutator<AdminTrainingCollectionComparisonVideo>(
      {url: `/api/admin/v1/training_collections_comparison_videos/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminTrainingCollectionComparisonVideo
    },
      );
    }
  export const adminV1TrainingCollectionsComparisonVideosRetrieve = (
    id: number,
 ) => {
      return adminV1TrainingCollectionsComparisonVideosRetrieveMutator<AdminTrainingCollectionComparisonVideo>(
      {url: `/api/admin/v1/training_collections_comparison_videos/${id}/`, method: 'GET'
    },
      );
    }
  export const adminV1TrainingCollectionsComparisonVideosUpdate = (
    id: number,
    adminTrainingCollectionComparisonVideo: NonReadonly<AdminTrainingCollectionComparisonVideo>,
 ) => {
      return adminV1TrainingCollectionsComparisonVideosUpdateMutator<AdminTrainingCollectionComparisonVideo>(
      {url: `/api/admin/v1/training_collections_comparison_videos/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: adminTrainingCollectionComparisonVideo
    },
      );
    }
  export const adminV1TrainingCollectionsComparisonVideosPartialUpdate = (
    id: number,
    patchedAdminTrainingCollectionComparisonVideo: NonReadonly<PatchedAdminTrainingCollectionComparisonVideo>,
 ) => {
      return adminV1TrainingCollectionsComparisonVideosPartialUpdateMutator<AdminTrainingCollectionComparisonVideo>(
      {url: `/api/admin/v1/training_collections_comparison_videos/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedAdminTrainingCollectionComparisonVideo
    },
      );
    }
  export const adminV1TrainingCollectionsComparisonVideosDestroy = (
    id: number,
 ) => {
      return adminV1TrainingCollectionsComparisonVideosDestroyMutator<void>(
      {url: `/api/admin/v1/training_collections_comparison_videos/${id}/`, method: 'DELETE'
    },
      );
    }
  export const adminV1TrainingCollectionsComparisonVideosUpdateVideosOrderCreate = (
    orderTrainingCollectionComparisonVideos: OrderTrainingCollectionComparisonVideos[],
 ) => {
      return adminV1TrainingCollectionsComparisonVideosUpdateVideosOrderCreateMutator<OrderTrainingCollectionComparisonVideosResponse>(
      {url: `/api/admin/v1/training_collections_comparison_videos/update_videos_order/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: orderTrainingCollectionComparisonVideos
    },
      );
    }
  export type AdminV1TrainingCollectionsComparisonVideosListResult = NonNullable<Awaited<ReturnType<typeof adminV1TrainingCollectionsComparisonVideosList>>>
export type AdminV1TrainingCollectionsComparisonVideosCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1TrainingCollectionsComparisonVideosCreate>>>
export type AdminV1TrainingCollectionsComparisonVideosRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1TrainingCollectionsComparisonVideosRetrieve>>>
export type AdminV1TrainingCollectionsComparisonVideosUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1TrainingCollectionsComparisonVideosUpdate>>>
export type AdminV1TrainingCollectionsComparisonVideosPartialUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1TrainingCollectionsComparisonVideosPartialUpdate>>>
export type AdminV1TrainingCollectionsComparisonVideosDestroyResult = NonNullable<Awaited<ReturnType<typeof adminV1TrainingCollectionsComparisonVideosDestroy>>>
export type AdminV1TrainingCollectionsComparisonVideosUpdateVideosOrderCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1TrainingCollectionsComparisonVideosUpdateVideosOrderCreate>>>
