import { computed, ref, toRef, watch, type ComputedRef, type Ref } from "vue";
import { useToast } from "primevue/usetoast";
import { format } from "date-fns";
import type { CreateInvoices } from "@/api/model";
import type {
  InvoiceFormFields,
  InvoiceRecipient,
  ValidInvoiceForm,
  ValidInvoiceRecipient,
} from "@/types";
import { createInvoice as createInvoiceAPI } from "../api";

interface UseCreateInvoices {
  invoiceCreateError: Ref<string | null>;
  createInvoices: () => Promise<boolean>;
  invoice: Ref<CreateInvoices | null>;
  invoiceRecipientsMissingContacts: ComputedRef<InvoiceRecipient[]>;
  validInvoiceRecipients: ComputedRef<InvoiceRecipient[]>;
}

export function useCreateInvoices(
  invoiceFormFields: InvoiceFormFields
): UseCreateInvoices {
  const toast = useToast();
  const invoiceCreateError = ref<string | null>(null);
  const invoice = ref<CreateInvoices | null>(null);
  const { invoiceRecipients } = invoiceFormFields;

  const validInvoiceRecipients: ComputedRef<ValidInvoiceRecipient[]> = computed(
    () =>
      invoiceRecipients.value.filter(
        (recipient): recipient is ValidInvoiceRecipient =>
          recipient.associatedContactId !== undefined
      )
  );

  const invoiceRecipientsMissingContacts = computed(() =>
    invoiceRecipients.value.filter(
      (recipient) => !recipient.associatedContactId
    )
  );

  const createInvoices = async (): Promise<boolean> => {
    const validatedInvoice = validateInvoiceForm(invoiceFormFields);

    if (!validatedInvoice) {
      showErrorToast("Invalid invoice data. Please check your inputs.");
      return false;
    }

    const apiPayload = buildApiRequest(validatedInvoice);
    const result = await createInvoiceAPI(apiPayload);

    if ("error" in result) {
      showErrorToast(result.error);
      return false;
    }

    const isBulkInvoice = validInvoiceRecipients.value.length > 1;

    if (isBulkInvoice) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail:
          "Invoices have been queued to be sent. Please look out for an email confirming that all invoices have been processed.",
        life: 6000,
      });
    } else {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Invoice has been sent.",
        life: 4000,
      });
    }

    invoice.value = result;
    return true;
  };

  const buildApiRequest = (
    validatedInvoice: ValidInvoiceForm
  ): CreateInvoices => {
    const { invoiceDueDate, invoiceTitle, lineItems } = validatedInvoice;

    const recipients = validInvoiceRecipients.value.map((recipient) => ({
      profile_id: recipient.playerId,
      recipient_id: recipient.associatedContactId,
    }));

    const request = {
      invoice_due_date: format(invoiceDueDate, "yyyy-MM-dd"),
      invoice_title: invoiceTitle,
      items: lineItems.map((item) => ({
        item_product_name: item.itemName,
        item_cost: item.itemPrice,
        item_qty: item.itemQuantity,
        item_categories: [item.itemCategory],
      })),
      profile_recipient_tuples: recipients,
    };

    return request;
  };

  const showErrorToast = (detail: string) => {
    toast.add({
      severity: "error",
      summary: "Error",
      detail,
      life: 3000,
    });
  };

  const validateInvoiceForm = (
    invoiceFormFields: InvoiceFormFields
  ): ValidInvoiceForm | undefined => {
    const { invoiceTitle, invoiceDueDate, lineItems } = invoiceFormFields;

    if (
      invoiceTitle.value &&
      invoiceDueDate.value &&
      lineItems.value.length > 0
    ) {
      if (validInvoiceRecipients) {
        return {
          invoiceTitle: invoiceTitle.value,
          invoiceDueDate: invoiceDueDate.value,
          lineItems: lineItems.value,
          invoiceRecipients: validInvoiceRecipients.value,
        };
      }
    }
  };
  return {
    createInvoices,
    invoiceCreateError,
    invoice,
    validInvoiceRecipients,
    invoiceRecipientsMissingContacts,
  };
}
