/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminProfileAssociatedContact,
  AdminV1ProfilesAssociatedContactsListParams,
  PaginatedAdminProfileAssociatedContactList,
  PatchedAdminProfileAssociatedContact
} from '.././model'
import adminV1ProfilesAssociatedContactsListMutator from '../../shared/services/axios-client';
import adminV1ProfilesAssociatedContactsCreateMutator from '../../shared/services/axios-client';
import adminV1ProfilesAssociatedContactsRetrieveMutator from '../../shared/services/axios-client';
import adminV1ProfilesAssociatedContactsUpdateMutator from '../../shared/services/axios-client';
import adminV1ProfilesAssociatedContactsPartialUpdateMutator from '../../shared/services/axios-client';
import adminV1ProfilesAssociatedContactsDestroyMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;




  export const adminV1ProfilesAssociatedContactsList = (
    profilePk: number,
    params?: AdminV1ProfilesAssociatedContactsListParams,
 ) => {
      return adminV1ProfilesAssociatedContactsListMutator<PaginatedAdminProfileAssociatedContactList>(
      {url: `/api/admin/v1/profiles/${profilePk}/associated_contacts/`, method: 'GET',
        params
    },
      );
    }
  export const adminV1ProfilesAssociatedContactsCreate = (
    profilePk: number,
    adminProfileAssociatedContact: NonReadonly<AdminProfileAssociatedContact>,
 ) => {
      return adminV1ProfilesAssociatedContactsCreateMutator<AdminProfileAssociatedContact>(
      {url: `/api/admin/v1/profiles/${profilePk}/associated_contacts/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminProfileAssociatedContact
    },
      );
    }
  export const adminV1ProfilesAssociatedContactsRetrieve = (
    profilePk: number,
    id: number,
 ) => {
      return adminV1ProfilesAssociatedContactsRetrieveMutator<AdminProfileAssociatedContact>(
      {url: `/api/admin/v1/profiles/${profilePk}/associated_contacts/${id}/`, method: 'GET'
    },
      );
    }
  export const adminV1ProfilesAssociatedContactsUpdate = (
    profilePk: number,
    id: number,
    adminProfileAssociatedContact: NonReadonly<AdminProfileAssociatedContact>,
 ) => {
      return adminV1ProfilesAssociatedContactsUpdateMutator<AdminProfileAssociatedContact>(
      {url: `/api/admin/v1/profiles/${profilePk}/associated_contacts/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: adminProfileAssociatedContact
    },
      );
    }
  export const adminV1ProfilesAssociatedContactsPartialUpdate = (
    profilePk: number,
    id: number,
    patchedAdminProfileAssociatedContact: NonReadonly<PatchedAdminProfileAssociatedContact>,
 ) => {
      return adminV1ProfilesAssociatedContactsPartialUpdateMutator<AdminProfileAssociatedContact>(
      {url: `/api/admin/v1/profiles/${profilePk}/associated_contacts/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedAdminProfileAssociatedContact
    },
      );
    }
  /**
 * Remove the contact from the profile. If the contact is the primary contact, remove the association.
 * @summary Remove the contact from the profile. If the contact is the primary contact, remove the association.
 */
export const adminV1ProfilesAssociatedContactsDestroy = (
    profilePk: number,
    id: number,
 ) => {
      return adminV1ProfilesAssociatedContactsDestroyMutator<void>(
      {url: `/api/admin/v1/profiles/${profilePk}/associated_contacts/${id}/`, method: 'DELETE'
    },
      );
    }
  export type AdminV1ProfilesAssociatedContactsListResult = NonNullable<Awaited<ReturnType<typeof adminV1ProfilesAssociatedContactsList>>>
export type AdminV1ProfilesAssociatedContactsCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1ProfilesAssociatedContactsCreate>>>
export type AdminV1ProfilesAssociatedContactsRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1ProfilesAssociatedContactsRetrieve>>>
export type AdminV1ProfilesAssociatedContactsUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1ProfilesAssociatedContactsUpdate>>>
export type AdminV1ProfilesAssociatedContactsPartialUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1ProfilesAssociatedContactsPartialUpdate>>>
export type AdminV1ProfilesAssociatedContactsDestroyResult = NonNullable<Awaited<ReturnType<typeof adminV1ProfilesAssociatedContactsDestroy>>>
