/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminAssociatedContact,
  AdminAssociatedContactAddProfile,
  AdminV1AssociatedContactsListParams,
  PaginatedAdminAssociatedContactList,
  PatchedAdminAssociatedContact
} from '.././model'
import adminV1AssociatedContactsListMutator from '../../shared/services/axios-client';
import adminV1AssociatedContactsUpdateMutator from '../../shared/services/axios-client';
import adminV1AssociatedContactsPartialUpdateMutator from '../../shared/services/axios-client';
import adminV1AssociatedContactsAddProfileCreateMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;




  export const adminV1AssociatedContactsList = (
    params?: AdminV1AssociatedContactsListParams,
 ) => {
      return adminV1AssociatedContactsListMutator<PaginatedAdminAssociatedContactList>(
      {url: `/api/admin/v1/associated_contacts/`, method: 'GET',
        params
    },
      );
    }
  export const adminV1AssociatedContactsUpdate = (
    id: number,
    adminAssociatedContact: NonReadonly<AdminAssociatedContact>,
 ) => {
      return adminV1AssociatedContactsUpdateMutator<AdminAssociatedContact>(
      {url: `/api/admin/v1/associated_contacts/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: adminAssociatedContact
    },
      );
    }
  export const adminV1AssociatedContactsPartialUpdate = (
    id: number,
    patchedAdminAssociatedContact: NonReadonly<PatchedAdminAssociatedContact>,
 ) => {
      return adminV1AssociatedContactsPartialUpdateMutator<AdminAssociatedContact>(
      {url: `/api/admin/v1/associated_contacts/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedAdminAssociatedContact
    },
      );
    }
  export const adminV1AssociatedContactsAddProfileCreate = (
    id: number,
    adminAssociatedContactAddProfile: AdminAssociatedContactAddProfile,
 ) => {
      return adminV1AssociatedContactsAddProfileCreateMutator<AdminAssociatedContact>(
      {url: `/api/admin/v1/associated_contacts/${id}/add_profile/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminAssociatedContactAddProfile
    },
      );
    }
  export type AdminV1AssociatedContactsListResult = NonNullable<Awaited<ReturnType<typeof adminV1AssociatedContactsList>>>
export type AdminV1AssociatedContactsUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1AssociatedContactsUpdate>>>
export type AdminV1AssociatedContactsPartialUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1AssociatedContactsPartialUpdate>>>
export type AdminV1AssociatedContactsAddProfileCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1AssociatedContactsAddProfileCreate>>>
